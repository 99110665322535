<template>
  <div>
    <b-row class="align-self-stretch">
      <b-col
          xl="3"
          sm="12"
          class="h-100"
      >
        <b-overlay :show="loading">
          <b-card
              title="Helyfoglalás főkapcsoló"
          >
            <b-form-group>
              <switches
                  v-model="reservationEnabled"
                  text-enabled="Nyitva"
                  text-disabled="Zárva"
                  type-bold="true"
                  theme="custom"
                  color="primary"
                  :emit-on-mount="false"
                  @input="setReservableStatus"
              />
            </b-form-group>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
          xl="6"
          sm="12"
          class="h-100"
      >
        <b-overlay :show="loading">
          <b-card
              title="Tárgyév választó"
          >
            <b-form-group
                description="A tárgyév csak akkor változtatható, ha a helyfoglalás zárva van."
            >
              <v-select
                  v-model="referenceYear"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :disabled="reservationEnabled"
                  :clearable="false"
                  size="xl"
                  :options="referenceYearOptions"
                  @input="setReferenceYear"
              />
            </b-form-group>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
          xl="3"
          sm="12"
          class="h-100"
      >
        <b-overlay :show="loading">
          <b-card
              title="Átfedő foglalások"
          >
            <b-form-group
                description="Átfedő foglalások lekérdezése"
            >
              <b-button
                  variant="info"
                  @click="downloadOverlappingReservations"
              >Letöltés
              </b-button>
            </b-form-group>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <b-row v-if="showTodo">
      <b-col
          sm="12"
      >
        <b-overlay :show="loading">
          <b-card
              title="Tennivalók"
          >
            <reservation-list ref="todo" :only-todo="true"></reservation-list>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BCard, BCol, BFormGroup, BOverlay, BRow, BButton} from "bootstrap-vue";
import Switches from "vue-switches";
import vSelect from "vue-select";
import ReservationList from "../../reservation/List"

export default {
  props: {
    showTodo: {
      type: Boolean,
      required: true
    }
  },
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    ReservationList,
    Switches,
    vSelect,
  },
  created() {
    this.initData()
  },
  data() {
    return {
      role: 'reservation',
      reservationEnabled: false,
      referenceYear: '',
      loading: false,
      referenceYearOptions: [],
    }
  },
  methods: {
    initData() {
      this.loading = true;

      this.$store.dispatch('fetchReferenceYearsForSelect').then(response => {
        this.referenceYearOptions = response
      })

      this.$store.dispatch('fetchReservationDashboard').then(response => {
        this.reservationEnabled = !Boolean(response.data.closed);
        this.referenceYear = {
          value: response.data.referenceYear.id,
          title: response.data.referenceYear.year
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    mounted() {
      this.$refs['todo'].initEntities()
    },
    setReservableStatus() {
      this.$store.dispatch('setReservableStatus', {
        closed: !this.reservationEnabled
      }).then(() => {
        this.$emit('functions-refreshed');
        this.initData()
      })
    },
    setReferenceYear() {
      this.$store.dispatch('setReferenceYear', {
        referenceYear: this.referenceYear.value
      }).then(() => {
        this.$emit('functions-refreshed');
        this.initData()
      })
    },
    downloadOverlappingReservations()
    {
      let today = this.$helpers.getCurrentDate('YYYY-MM-DD-hh-mm')
      this.loading = true
      this.$store.dispatch('downloadOverlappingReservations').then(response => {
        const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'atfedo-foglalasok-'+today+'.xlsx'
        link.click()
        URL.revokeObjectURL(link.href)

        this.$helpers.showSuccessToast()
      }).catch(error => {
        this.$helpers.showErrorToast(error.toString())
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
.vue-switcher-theme--custom {
  &.vue-switcher-color--primary {
    div {
      background-color: lighten(#29c770, 10%);

      &:after {
        background-color: darken(#29c770, 5%);
      }
    }

    &.vue-switcher--unchecked {
      div {
        background-color: lighten(#ea5556, 30%);

        &:after {
          background-color: lighten(#ea5556, 10%);
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
