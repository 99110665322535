<template>
  <div>
    <functions
        :show-todo="true"
        @functions-refreshed="functionsRefreshed"
    ></functions>
    <maconka-map
        ref="map"
        @map-refreshed="mapRefreshed"
    ></maconka-map>
    <calendars
        ref="calendars"
        :can-create-reservation="canCreateReservation"
        @calendar-refreshed="calendarRefreshed"
    ></calendars>
  </div>
</template>

<script>

import Functions from '../_components/_reservationDashboard/Functions';
import MaconkaMap from '../_components/_reservationDashboard/MaconkaMap';
import Calendars from '../_components/_reservationDashboard/Calendars';

export default {
  components: {
    Functions,
    MaconkaMap,
    Calendars,
  },
  data(){
    return {
      canCreateReservation: false
    }
  },
  methods: {
    functionsRefreshed() {
      this.$refs.map.initData()
      this.$refs.calendars.initData()
    },
    mapRefreshed(selectedMarkerId, selectedMarkerName, calendarData = null) {
      this.$refs.calendars.refreshData(selectedMarkerId, selectedMarkerName, calendarData)
      this.checkReservationState()
    },
    calendarRefreshed(calendarData) {
      this.$refs.map.refreshData(calendarData.dateFrom, calendarData.dateTo)
      this.checkReservationState()
    },
    checkReservationState() {
      let calendarData = this.$refs.calendars.getData()
      let mapData = this.$refs.map.getData()
      this.canCreateReservation = mapData.selectedMarkerId !== '' && calendarData.dateFrom !== null && calendarData.dateTo !== null
    },
  }
}
</script>
