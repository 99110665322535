<template>
  <div>
    <b-card
        :title="componentTitle"
    >
      <svg class="mt-3 mb-3" ref="map" :width="map.width" :height="map.height">
        <image
            :href="mapUrl"
            :width="map.width"
            :height="map.height"
        />
        <filter id="PATH_MARKER_WRAPPER">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur-16"/>
          <feFlood flood-opacity="0.161" result="color-9"/>
          <feComposite operator="out" in="SourceGraphic" in2="blur-16"/>
          <feComposite operator="in" in="color-9"/>
          <feComposite operator="in" in2="SourceGraphic"/>
        </filter>
        <g
            v-for="marker in markers"
            :key="marker.id"
            :id="'Group_' + marker.id" :data-name="'Group ' + marker.id"
            :transform="'translate('+marker.position.x+' '+marker.position.y+') ' + 'scale('+map.multiplier+')'"
            :class="'cursor-pointer marker state-'+marker.state"
            @click="selectMarker(marker.id)"
        >
          <g data-type="innerShadowGroup">
            <path class="marker-inner-circle" :id="'Path_' + marker.slug" :data-name="'Path ' + marker.slug"
                  d="M16.783,0A16.783,16.783,0,0,1,33.567,16.783a17.41,17.41,0,0,1-.579,4.388A16.785,16.785,0,1,1,16.783,0Z"
                  transform="translate(1.097 1.097)" fill="#f38e3a"/>
            <g transform="matrix(1, 0, 0, 1, -780.27, -543.35)" filter="url(#PATH_MARKER_WRAPPER)">
              <path :id="'Path_' + marker.slug + '_1'" :data-name="'Path ' + marker.slug + '_1'"
                    d="M16.783,0A16.783,16.783,0,0,1,33.567,16.783a17.41,17.41,0,0,1-.579,4.388A16.785,16.785,0,1,1,16.783,0Z"
                    transform="translate(781.37 544.45)" fill="#fff"/>
            </g>
          </g>
          <path class="marker-outer-circle" :id="'Path_' + marker.slug + '_2'"
                :data-name="'Path ' + marker.slug + '_2'"
                d="M259.843,637.367a17.881,17.881,0,1,1,17.881-17.881A17.9,17.9,0,0,1,259.843,637.367Zm0-33.567a15.686,15.686,0,1,0,15.686,15.686A15.7,15.7,0,0,0,259.843,603.8Z"
                transform="translate(-241.962 -601.605)" fill="#faaa68"/>
          <text class="marker-text" :id="'Path_' + marker.slug + '_t'"
                :data-name="'Path ' + marker.slug + '_t'"
                transform="matrix(1, -0.01, 0.01, 1, 9, 22)" fill="#fff" :font-size="map.marker.fontSize"
                font-family="Poppins-SemiBold, Poppins" font-weight="600">
            <tspan x="0" y="0">{{ marker.label }}</tspan>
          </text>
        </g>
      </svg>
      <!-- TODO: ha nincs fotó, akkor a b-popover kapjon egy popover-narrow osztályt -->
      <b-popover v-for="marker in markers"
                 :key="'popover-'+marker.id"
                 :target="'Group_'+marker.id"
                 :variant="(Object.keys(marker.gallery).length === 0) ? 'narrow' : ''"
                 triggers="hover"
                 placement="top"
                 data-popover="map"
      >

        <div v-if="Object.keys(marker.gallery).length > 0" class="popover-left">
          <div class="popover-gallery">
            <img v-for="(image, index) in marker.gallery" v-if="index === 0"
                 :data-fancybox="'marker-'+marker.id+'-gallery'" :data-src="image" style="max-width: 379px;"
                 :src="image">
          </div>
        </div>

        <div class="popover-right">
          <div class="popover-title">{{ marker.name }}</div>

          <button class="popover-link" @click.stop="$router.push({ name: 'modify-reservation-fishing-spot', params: { id: marker.id }})">Horgászhely megtekintése</button>

          <div class="popover-count">
            <div class="popover-count-item popover-count-item-maxAdult">
              <div class="popover-count-icon">
                <img :id="'tooltip-maxAdultYoungster-'+marker.id" :src="baseUrl+'/img/icons/reservation/fisherman.svg'" width="31" height="36"
                     alt="Maximális horgászlétszám (felnőtt + ifi)">
              </div>
              <p>{{ marker.minAdultYoungster }}-{{ marker.maxAdultYoungster }} fő</p>
              <b-tooltip :target="'tooltip-maxAdultYoungster-'+marker.id">Maximális horgászlétszám (felnőtt + ifi)</b-tooltip>
            </div>
            <div class="popover-count-item">
              <div class="popover-count-icon">
                <img :id="'tooltip-maxChildAttendant-'+marker.id"
                     :src="baseUrl+'/img/icons/reservation/child-attendant.svg'"
                     alt="Maximális egyéb létszám (gyermek + kísérő)"
                     width="28" height="31">
              </div>
              <p>{{ marker.maxChildAttendant }} fő</p>
              <b-tooltip :target="'tooltip-maxChildAttendant-'+marker.id">Maximális egyéb létszám (gyermek + kísérő)</b-tooltip>
            </div>
          </div>

          <div class="popover-equipment">
            <img :id="'tooltip-hasShower-'+marker.id" width="57" height="57" v-if="marker.hasShower"
                 :src="baseUrl+'/img/icons/reservation/shower.svg'"
                 alt="H/M zuhanyzási lehetőség, vízöblítéses WC biztosított">
            <b-tooltip :target="'tooltip-hasShower-'+marker.id" v-if="marker.hasShower">H/M zuhanyzási lehetőség, vízöblítéses WC biztosított</b-tooltip>

            <img :id="'tooltip-hasPowerConsumption-'+marker.id" width="57" height="57"
                 v-if="marker.hasPowerConsumption" :src="baseUrl+'/img/icons/reservation/power-consumption.svg'"
                 alt="Áramfelvételi lehetőség külön térítés mellett">
            <b-tooltip :target="'tooltip-hasPowerConsumption-'+marker.id" v-if="marker.hasPowerConsumption">Áramfelvételi lehetőség külön térítés mellett</b-tooltip>

            <img :id="'tooltip-canUseTentFull-'+marker.id" width="57" height="57"
                 v-if="marker.canUseTentFull"
                 :src="baseUrl+'/img/icons/reservation/tent.svg'" alt="Teljes sátor használható">
            <b-tooltip :target="'tooltip-canUseTentFull-'+marker.id" v-if="marker.canUseTentFull">Teljes sátor használható</b-tooltip>

            <img :id="'tooltip-canUseTentHalf-'+marker.id" width="57" height="57"
                 v-if="marker.canUseTentHalf"
                 :src="baseUrl+'/img/icons/reservation/half-tent.svg'" alt="Csak félsátor használata engedélyezett">
            <b-tooltip :target="'tooltip-canUseTentHalf-'+marker.id" v-if="marker.canUseTentHalf">Csak félsátor használata engedélyezett</b-tooltip>

            <img :id="'tooltip-hasStreetlight-'+marker.id" width="57" height="57"
                 v-if="marker.hasStreetlight"
                 :src="baseUrl+'/img/icons/reservation/street-light.svg'" alt="Közvilágítás biztosított">
            <b-tooltip :target="'tooltip-hasStreetlight-'+marker.id" v-if="marker.hasStreetlight">Közvilágítás biztosított</b-tooltip>

            <img :id="'tooltip-hasFireplace-'+marker.id" width="57" height="57" v-if="marker.hasFireplace"
                 :src="baseUrl+'/img/icons/reservation/fireplace.svg'" alt="Kiépített tűzrakó hely van">
            <b-tooltip :target="'tooltip-hasFireplace-'+marker.id" v-if="marker.hasFireplace">Kiépített tűzrakó hely van</b-tooltip>

            <img :id="'tooltip-hasPavilion-'+marker.id" width="57" height="57" v-if="marker.hasPavilion"
                 :src="baseUrl+'/img/icons/reservation/pavilion.svg'"
                 alt="Kiépített esőbeálló asztallal és padokkal (filagória) biztosított">
            <b-tooltip :target="'tooltip-hasPavilion-'+marker.id" v-if="marker.hasPavilion">Kiépített esőbeálló asztallal és padokkal (filagória) biztosított</b-tooltip>

            <img :id="'tooltip-canUseCaravan-'+marker.id" width="57" height="57" v-if="marker.canUseCaravan"
                 :src="baseUrl+'/img/icons/reservation/caravan.svg'"
                 alt="Lakókocsi, lakóbusz a hely közelében elhelyezhető">
            <b-tooltip :target="'tooltip-canUseCaravan-'+marker.id" v-if="marker.canUseCaravan">Lakókocsi, lakóbusz a hely közelében elhelyezhető</b-tooltip>

            <img :id="'tooltip-moreInfo-'+marker.id" width="57" height="57"
                 v-if="marker.moreInfo && marker.moreInfo.length" :src="baseUrl+'/img/icons/reservation/info.svg'"
                 alt="Egyéb információ">
            <b-tooltip :target="'tooltip-moreInfo-'+marker.id" v-if="marker.moreInfo && marker.moreInfo.length"><span v-html="marker.moreInfo"></span></b-tooltip>
          </div>
        </div>
      </b-popover>
      <div class="map-legend">
        <div class="row">
          <div class="col map-legend-item">
            <img class="map-legend-icon" :src="baseUrl+'/img/icons/reservation/general-fishing-opportunity.svg'"
                 alt="Általános parti horgászati lehetőség"> Általános parti horgászati lehetőség
          </div>
          <div class="col map-legend-item">
            <img class="map-legend-icon" :src="baseUrl+'/img/icons/reservation/paved-path.svg'"
                 alt="Szilárdburkolatú belső út"> Szilárdburkolatú belső út
          </div>
          <div class="col map-legend-item">
            <img class="map-legend-icon" :src="baseUrl+'/img/icons/reservation/parking.svg'" alt="Parkoló"> Parkoló
          </div>
          <div class="col map-legend-item">
            <img class="map-legend-icon" :src="baseUrl+'/img/icons/reservation/shower.svg'" alt="Zuhanyzó / ivókút">
            Zuhanyzó / ivókút
          </div>
        </div>
        <div class="row">
          <div class="col map-legend-item">
            <img class="map-legend-icon" :src="baseUrl+'/img/icons/reservation/conditional-fishing-opportunity.svg'"
                 alt="Feltételes parti horgászati lehetőség"> Feltételes parti horgászati lehetőség
          </div>
          <div class="col map-legend-item">
            <img class="map-legend-icon" :src="baseUrl+'/img/icons/reservation/dirt-road.svg'" alt="Földút"> Földút
          </div>
          <div class="col map-legend-item">
            <img class="map-legend-icon" :src="baseUrl+'/img/icons/reservation/wc.svg'" alt="Toalet"> Toalet
          </div>
          <div class="col map-legend-item">
            <img class="map-legend-icon" :src="baseUrl+'/img/icons/reservation/boat-harbor.svg'" alt="Csónakkikötő">
            Csónakkikötő
          </div>
        </div>
        <div class="row">
          <div class="col map-legend-item">
            <img class="map-legend-icon" :src="baseUrl+'/img/icons/reservation/public-road.svg'" alt="Közút"> Közút
          </div>
          <div class="col map-legend-item">
            <img class="map-legend-icon" :src="baseUrl+'/img/icons/reservation/sluice.svg'" alt="Zsilip / áteresz">
            Zsilip
            / áteresz
          </div>
          <div class="col map-legend-item">
            <img class="map-legend-icon" :src="baseUrl+'/img/icons/reservation/parking-special.svg'"
                 alt="Parkolás külön feltételekkel"> Parkolás külön feltételekkel
          </div>
          <div class="col map-legend-item">
            <img class="map-legend-icon" :src="baseUrl+'/img/icons/reservation/fishing-spot.svg'"
                 alt="Foglalható helyek">
            Foglalható helyek
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>

import {BPopover, BTooltip, BOverlay, BCard} from 'bootstrap-vue';

export default {
  components: {
    BPopover,
    BOverlay,
    BTooltip,
    BCard
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_SERVER_BASE_URL,
      componentTitle: 'Horgászhelyek',
      selectedMarkerId: '',
      minimumFee: 0,
      map: {
        width: 1300,
        height: 750,
        reference: {
          width: 1538.175,
          height: 897.623,
        },
        // osztó szám
        multiplier: 1,
        // marker default size for reference
        marker: {
          width: 34,
          height: 34,
          fontSize: 12,
        }
      },
      markers: [],
      mapUrl: '',
      dateFrom: '',
      dateTo: '',
      loading: false
    }
  },
  created() {
    this.initData()
  },
  methods: {
    selectMarker(id, refreshCalendars = true) {

      let selectedMarkerName = this.setMarkersState(id)
      if(refreshCalendars) {
        this.$emit('map-refreshed', this.selectedMarkerId, selectedMarkerName)
      }
    },
    setMarkersState(id) {
      let selectedMarkerName = '';

      Object.keys(this.markers).forEach(key => {
        if (this.markers[key].id === id && this.markers[key].originalState !== 'disabled') {
          this.markers[key].state = 'selected'
          this.selectedMarkerId = id
          selectedMarkerName = this.markers[key].name
          this.minimumFee = this.markers[key].minimumFee
        } else {
          this.markers[key].state = this.markers[key].originalState
        }
      })

      return selectedMarkerName
    },
    initData(refreshCalendars = true) {
      this.loading = true;
      this.$store.dispatch('fetchReservationMapMarkers', {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      }).then(res => {
        this.markers = res.data.markers
        this.mapUrl = res.data.mapUrl
      }).catch(() => {
        //TODO: error handling
      }).finally(() => {
        this.loading = false
      });
    },
    refreshData(dateFrom, dateTo) {
      if (dateFrom && dateTo) {
        this.dateFrom = dateFrom
        this.dateTo = dateTo
        this.componentTitle = 'Horgászhelyek állapota: ' + dateFrom + ' - ' + dateTo
        this.initData(false)
      }
    },
    resetData() {
      this.selectedMarkerId = ''
      Object.keys(this.markers).forEach(key => {
        this.markers[key].state = 'free'
      })
      this.componentTitle = 'Horgászhelyek'
      this.initData(false)
    },
    getData() {
      return {
        selectedMarkerId: this.selectedMarkerId,
        minimumFee: this.minimumFee
      }
    },
  }
}
</script>

<style>

.month-container {
  margin-top: 30px;
}

svg:hover image {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

svg:hover .state-free .marker-inner-circle,
svg:hover .state-free .marker-outer-circle {
  fill: #328C86;
}

svg .state-own .marker-inner-circle,
svg .state-own .marker-outer-circle {
  fill: #F33A4F !important;
}

svg .state-selected .marker-inner-circle,
svg .state-selected .marker-outer-circle{
  fill: #F38E3A !important;
}

svg:hover .state-disabled .marker-inner-circle,
svg:hover .state-disabled .marker-outer-circle {
  fill: #D6D6D6;
}

svg:hover .state-free:hover .marker-inner-circle,
svg:hover .state-free:hover .marker-outer-circle,
svg:hover .state-selected:hover .marker-inner-circle,
svg:hover .state-selected:hover .marker-outer-circle {
  fill: #F38E3A;
}

.cursor-pointer {
  cursor: pointer
}
</style>

<style lang="scss">
div[data-popover="map"] {
  max-width: 871px;
  width: 100%;
  border-color: #FFF;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-clip: border-box;
  box-shadow: 0 28px 30px rgba(0, 0, 0, 0.06); }
div[data-popover="map"].b-popover-narrow {
  max-width: 340px; }
div[data-popover="map"].b-popover-narrow .popover-right {
  max-width: 100%;
  flex-basis: 100%;
  padding-left: 0;
  padding-right: 0; }
div[data-popover="map"] .popover-body {
  display: flex; }
div[data-popover="map"] .popover-left {
  max-width: 379px;
  flex-basis: 379px; }
div[data-popover="map"] .popover-left img {
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-clip: border-box; }
div[data-popover="map"] .popover-right {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 513px);
  flex-basis: calc(100% - 513px);
  padding-left: 32px;
  padding-right: 16px; }
div[data-popover="map"] .popover-title {
  font-size: 25px;
  font-weight: 600;
  color: #151F2B;
  line-height: 1.2;
  margin-bottom: 10px; }
div[data-popover="map"] .popover-link {
  font-size: 15px;
  font-weight: 600;
  color: #324B65;
  text-align: left;
  border: none;
  background-color: transparent;
  padding: 0;
  margin-bottom: 17px; }
div[data-popover="map"] .popover-link:after {
  content: '';
  display: inline-block;
  width: 18px;
  height: 11px;
  background-image: url("../../../../assets/images/icons/arrow-right.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  margin-left: 10px; }
div[data-popover="map"] .popover-gallery {
  cursor: pointer; }
div[data-popover="map"] .popover-count {
  display: flex;
  align-items: center;
  margin-top: 14px;
  margin-bottom: 27px; }
div[data-popover="map"] .popover-count-item {
  display: flex;
  align-items: center;
  width: 130px;
  height: 61px;
  background-color: #FFF;
  border: 3px solid #F5F5F5;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  background-clip: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16); }
div[data-popover="map"] .popover-count-item p {
  max-width: calc(100% - 61px);
  flex-basis: calc(100% - 61px);
  font-size: 19px;
  font-weight: 500;
  color: #707070;
  text-align: center;
  margin-bottom: 0; }
div[data-popover="map"] .popover-count-item-maxAdult {
  margin-right: 10px; }
div[data-popover="map"] .popover-count-icon {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  width: 61px;
  height: 61px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-clip: border-box;
  background-color: #FFF;
  border: 3px solid #F5F5F5;
  margin-left: -3px; }
div[data-popover="map"] .popover-equipment {
  display: flex;
  flex-wrap: wrap;
  margin-right: -6px;
  margin-left: -6px; }
div[data-popover="map"] .popover-equipment img {
  margin: 0 6px 5px; }

</style>
